import React from "react";
import { useState, useEffect } from "react";
import "./admission-form.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function AdmissionFormPlaygroupNarsingi() {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [grade, setGrade] = useState("");
  const [address, setAddress] = useState("");
  const [comments, setComments] = useState("");
  const [source, setSource] = useState("");

  //fathers details
  const [fathersName, setFathersName] = useState("");
  const [fathersEmailAddress, setFathersEmailAddress] = useState("");
  const [fathersMobileNumber, setFathersMobileNumber] = useState("");
  const [fathersOccupation, setFathersOccupation] = useState("");
  const [fathersQualification, setFathersQualification] = useState("");

  //mothers details
  const [mothersName, setMothersName] = useState("");
  const [mothersEmailAddress, setMothersEmailAddress] = useState("");
  const [mothersMobileNumber, setMothersMobileNumber] = useState("");
  const [mothersOccupation, setMothersOccupation] = useState("");
  const [mothersQualification, setMothersQualification] = useState("");

  function submitForm() {
    var validated = 1;
    if (!name) {
      validated = 0;
      document.getElementById("name").style.border = "1px solid red";
    }

    if (!name.match(/^[a-zA-Z ]*$/)) {
      validated = 0;
      document.getElementById("name").style.border = "1px solid red";
    }

    if (!gender) {
      validated = 0;
      document.getElementById("gender").style.border = "1px solid red";
    }

    if (!dob) {
      validated = 0;
      document.getElementById("dob").style.border = "1px solid red";
    }

    if (!fathersName) {
      validated = 0;
      document.getElementById("fathers-name").style.border = "1px solid red";
    }

    if (!fathersName.match(/^[a-zA-Z ]*$/)) {
      validated = 0;
      document.getElementById("fathers-name").style.border = "1px solid red";
    }

    if (!fathersEmailAddress) {
      validated = 0;
      document.getElementById("fathers-email-address").style.border =
        "1px solid red";
    }
    if (
      !fathersEmailAddress.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      validated = 0;
      document.getElementById("fathers-email-address").style.borderColor =
        "red";
    }
    if (!fathersMobileNumber) {
      validated = 0;
      document.getElementById("fathers-mobile-number").style.border =
        "1px solid red";
    }

    if (
      !fathersMobileNumber.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
    ) {
      validated = 0;
      document.getElementById("fathers-mobile-number").style.border =
        "1px solid red";
    }

    if (!mothersName) {
      validated = 0;
      document.getElementById("mothers-name").style.border = "1px solid red";
    }

    if (!mothersName.match(/^[a-zA-Z ]*$/)) {
      validated = 0;
      document.getElementById("mothers-name").style.border = "1px solid red";
    }

    if (!mothersEmailAddress) {
      validated = 0;
      document.getElementById("mothers-email-address").style.border =
        "1px solid red";
    }
    if (
      !mothersEmailAddress.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      validated = 0;
      document.getElementById("mothers-email-address").style.borderColor =
        "red";
    }
    if (!mothersMobileNumber) {
      validated = 0;
      document.getElementById("mothers-mobile-number").style.border =
        "1px solid red";
    }

    if (
      !mothersMobileNumber.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
    ) {
      validated = 0;
      document.getElementById("mothers-mobile-number").style.border =
        "1px solid red";
    }

    if (!address) {
      validated = 0;
      document.getElementById("address").style.border = "1px solid red";
    }

    if (!comments) {
      validated = 0;
      document.getElementById("comments").style.border = "1px solid red";
    }

    if (!source) {
      validated = 0;
      document.getElementById("source").style.border = "1px solid red";
    }

    const yearOfBirth = new Date(dob).toLocaleDateString("en-GB", {
      year: "numeric",
    });
    console.log({ yearOfBirth });
    if (
      yearOfBirth !== "2021" &&
      yearOfBirth !== "2020" &&
      yearOfBirth !== "2019"
    ) {
      validated = 0;
      document.getElementById("grade").style.border = "1px solid red";
    }

    if (validated == 1) {
      fetch(`https://fks-server.vercel.app/api/saveFormData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: new Date().toLocaleDateString("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          name: name,
          gender: gender,
          dob: new Date(dob).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          grade: (() => {
            const yearOfBirth = new Date(dob).toLocaleDateString("en-GB", {
              year: "numeric",
            });

            if (yearOfBirth === "2021") return "PRE - KG";
            else if (yearOfBirth === "2020") return "Lower - KG";
            else if (yearOfBirth === "2019") return "Upper - KG";
            else return "Not Qualified";
          })(),
          fathersName: fathersName,
          fathersEmailAddress: fathersEmailAddress,
          fathersMobileNumber: fathersMobileNumber,
          fathersQualification: fathersQualification,
          fathersOccupation: fathersOccupation,
          mothersName: mothersName,
          mothersEmailAddress: mothersEmailAddress,
          mothersMobileNumber: mothersMobileNumber,
          mothersQualification: mothersQualification,
          mothersOccupation: mothersOccupation,
          address: address,
          comments: comments,
          source: source,
          branch: "NSG",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            clearInput();
            setRegistrationNumber(`NSG-2024-${data.data.submissionId}`);
            showPopUp();
          } else {
            alert(
              "Admission request with one of the email addresses or phone numbers you entered, already exists."
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function clearInput() {
    setName("");
    setGender("");
    setDOB("");
    setGrade("");
    setFathersName("");
    setFathersEmailAddress("");
    setFathersMobileNumber("");
    setFathersOccupation("");
    setFathersQualification("");
    setMothersName("");
    setMothersEmailAddress("");
    setMothersMobileNumber("");
    setMothersOccupation("");
    setMothersQualification("");
    setAddress("");
    setComments("");
    setSource("");
  }

  function showPopUp() {
    document.getElementById("adm-form-screen").style.display = "block";
    document.getElementById("adm-form-popup").style.display = "flex";
  }

  function hidePopUp() {
    document.getElementById("adm-form-screen").style.display = "none";
    document.getElementById("adm-form-popup").style.display = "none";
    window.location.href = "/playgroup-narsingi/connect";
  }

  function resetBorder(x) {
    x.style.border = "1px solid #000";
  }

  return (
    <>
      <div className="section">
        <div className="section-content text-align-center">
          <span className="title">ADMISSIONS FORM</span>
          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Child's Name (as per official Id)*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="name"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Gender*
                </label>
                <select
                  disabled
                  className="admission-form-input"
                  id="gender"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setGender(e.target.value);
                  }}
                >
                  <option value="select">Select Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
            </div>
          </div>

          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Date of Birth*
                </label>
                <input
                  disabled
                  type="date"
                  className="admission-form-input"
                  id="dob"
                  placeholder="dd/mm/yyyy"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    resetBorder(document.getElementById("grade"));
                    setDOB(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Grade*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="grade"
                  value={(() => {
                    const yearOfBirth = new Date(dob).toLocaleDateString(
                      "en-GB",
                      {
                        year: "numeric",
                      }
                    );

                    if (yearOfBirth === "2021") return "PRE - KG";
                    else if (yearOfBirth === "2020") return "Lower - KG";
                    else if (yearOfBirth === "2019") return "Upper - KG";
                    else return "Not Qualified";
                  })()}
                />
              </div>
            </div>
          </div>

          <div className="admissions-form-row">
            <label className="landing-3e-section-title contact-form-title">
              Father's Name*
            </label>
            <input
              disabled
              type="text"
              className="admission-form-input"
              id="fathers-name"
              onChange={(e) => {
                e.preventDefault();
                resetBorder(e.target);
                setFathersName(e.target.value);
              }}
            />
          </div>
          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Father's Email ID*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="fathers-email-address"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setFathersEmailAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Father's Mobile No.*
                </label>
                {/* <input disabled type="text" className="admission-form-input" id="mobile" onChange={e=>{resetBorders(); setMobile(e.target.value)}}/> */}
                <div
                  className="admission-form-input mobile-number-field"
                  id="fathers-mobile-number"
                >
                  <PhoneInput
                    disabled
                    placeholder="Enter phone number"
                    value={fathersMobileNumber}
                    onChange={(e) => {
                      setFathersMobileNumber(e);
                      document.getElementById(
                        "fathers-mobile-number"
                      ).style.border = "1px solid #000";
                    }}
                    defaultCountry="IN"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Father's Occupation*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="fathers-occupation"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setFathersOccupation(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Father's Qualification*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="fathers-qualification"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setFathersQualification(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="admissions-form-row">
            <label className="landing-3e-section-title contact-form-title">
              Mother's Name*
            </label>
            <input
              disabled
              type="text"
              className="admission-form-input"
              id="mothers-name"
              onChange={(e) => {
                e.preventDefault();
                resetBorder(e.target);
                setMothersName(e.target.value);
              }}
            />
          </div>

          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Mother's Email ID*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="mothers-email-address"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setMothersEmailAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Mother's Mobile No.*
                </label>
                {/* <input disabled type="text" className="admission-form-input" id="mobile" onChange={e=>{resetBorders(); setMobile(e.target.value)}}/> */}
                <div
                  className="admission-form-input mobile-number-field"
                  id="mothers-mobile-number"
                >
                  <PhoneInput
                    disabled
                    placeholder="Enter phone number"
                    value={mothersMobileNumber}
                    onChange={(e) => {
                      setMothersMobileNumber(e);
                      document.getElementById(
                        "mothers-mobile-number"
                      ).style.border = "1px solid #000";
                    }}
                    defaultCountry="IN"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="admissions-form-row-dual">
            <div className="admissions-form-row-dual-area-1">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Mother's Occupation*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="mothers-occupation"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setMothersOccupation(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admissions-form-row-dual-area-2">
              <div className="admissions-form-row">
                <label className="landing-3e-section-title contact-form-title">
                  Mother's Qualification*
                </label>
                <input
                  disabled
                  type="text"
                  className="admission-form-input"
                  id="mothers-qualification"
                  onChange={(e) => {
                    e.preventDefault();
                    resetBorder(e.target);
                    setMothersQualification(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="admissions-form-row">
            <label className="landing-3e-section-title contact-form-title">
              Child's current residential address?*
            </label>
            <input
              disabled
              type="text"
              className="admission-form-input"
              id="address"
              onChange={(e) => {
                e.preventDefault();
                resetBorder(e.target);
                setAddress(e.target.value);
              }}
            />
          </div>
          <div className="admissions-form-row">
            <label className="landing-3e-section-title contact-form-title">
              How did you hear about us?*
            </label>
            <select
              disabled
              className="admission-form-input"
              id="source"
              onChange={(e) => {
                e.preventDefault();
                resetBorder(e.target);
                setSource(e.target.value);
              }}
            >
              <option value="select">Select Choice</option>
              <option value="Through a Parent">Through a Parent</option>
              <option value="Word of mouth">Word of mouth</option>
              <option value="Former Student">Former Student</option>
              <option value="Existing Student">Existing Student</option>
              <option value="FKS Team Member">FKS Team Member</option>
              <option value="Search engine">Search engine</option>
            </select>
          </div>
          <div className="admissions-form-row">
            <label className="landing-3e-section-title contact-form-title">
              Comments*
            </label>
            <textarea
              disabled
              type="text"
              className="admission-form-input comments-field"
              id="comments"
              onChange={(e) => {
                e.preventDefault();
                resetBorder(e.target);
                setComments(e.target.value);
              }}
            />
          </div>
          <button
            disabled
            className="admissions-form-button"
            onClick={submitForm}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="admissions-form-screen" id="adm-form-screen"></div>
      <div className="admissions-popup-container" id="adm-form-popup">
        <div className="admissions-popup">
          <span className="title">THANK YOU!</span>
          <span className="title">
            We have recieved your enquiry. Your registration number is-
            <br />
            {registrationNumber}
          </span>
          <button className="admission-button-close" onClick={hidePopUp}>
            CLOSE
          </button>
        </div>
      </div>
    </>
  );
}

export default AdmissionFormPlaygroupNarsingi;
