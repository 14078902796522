import "./nav.css";
import React from "react";
import NoticeBannerPlaygroup from "../../../componenets/notice-banner-playgroup/notice-banner";

function NavPlaygroupNarsingi() {
  //about nav
  function showAboutNav() {
    document.getElementById("about-us-nav").style.display = "block";
  }

  function hideAboutNav() {
    document.getElementById("about-us-nav").style.display = "none";
  }

  //learning nav
  function showLearningNav() {
    document.getElementById("learning-nav").style.display = "block";
  }

  function hideLearningNav() {
    document.getElementById("learning-nav").style.display = "none";
  }

  //beyond learning nav
  function showBeyondLearningNav() {
    document.getElementById("beyond-learning-nav").style.display = "block";
  }

  function hideBeyondLearningNav() {
    document.getElementById("beyond-learning-nav").style.display = "none";
  }

  function openSideNav(e) {
    document.getElementById("side-nav").style.display = "block";
    document.getElementsByClassName("screen")[0].style.display = "block";
  }

  function closeSideNav(e) {
    document.getElementById("side-nav").style.display = "none";
    document.getElementsByClassName("screen")[0].style.display = "none";
  }

  function openAboutNav(e) {
    document.getElementById("about-nav-mob").style.display = "block";
    document.getElementById("learning-nav-mob").style.display = "none";
    document.getElementById("beyond-learning-nav-mob").style.display = "none";
  }

  function openLearningNav(e) {
    document.getElementById("learning-nav-mob").style.display = "block";
    document.getElementById("about-nav-mob").style.display = "none";
    document.getElementById("beyond-learning-nav-mob").style.display = "none";
  }

  function openBeyondLearningNav(e) {
    document.getElementById("beyond-learning-nav-mob").style.display = "block";
    document.getElementById("about-nav-mob").style.display = "none";
    document.getElementById("learning-nav-mob").style.display = "none";
  }

  //mobile nav

  // function showAboutNavMob(){
  //     document.getElementById('learning-nav-mob').style.display="none";
  //     document.getElementById('beyond-learning-nav-mob').style.display="none";
  //     document.getElementById('about-us-nav-mob').style.display="block";
  // }
  // function showLearningNavMob(){
  //     document.getElementById('learning-nav-mob').style.display="block";
  //     document.getElementById('beyond-learning-nav-mob').style.display="none";
  //     document.getElementById('about-us-nav-mob').style.display="none";
  // }
  // function showBeyondLearningNavMob(){
  //     document.getElementById('learning-nav-mob').style.display="none";
  //     document.getElementById('beyond-learning-nav-mob').style.display="block";
  //     document.getElementById('about-us-nav-mob').style.display="none";
  // }

  function openContactPopup() {
    document.getElementById("contact-popup").style.display = "flex";
  }

  return (
    <div>
      <NoticeBannerPlaygroup />
      <div className="nav">
        <a href="/">
          <img className="nav-logo" src="/fks-logo.png"></img>
        </a>
        <div className="nav-item-container">
          <a href="/playgroup-narsingi" className="nav-item">
            Home
          </a>
          <a href="/playgroup-narsingi/our-story" className="nav-item">
            About Us
            {/* <div className="sub-nav" id="about-us-nav" >
                            <a href="/our-story" className="sub-nav-item">OUR STORY</a>
                            <a href="/vision-and-mission" className="sub-nav-item">PHILOSOPHY</a>
                            <a href="/vision-and-mission" className="sub-nav-item">VISION &amp; MISSION</a>
                            <a href="/fks-force" className="sub-nav-item">FKS FORCE</a>
                            <a href="/news-and-highlights" className="sub-nav-item">NEWS &amp; HIGHLIGHTS</a>
                        </div> */}
          </a>

          <a href="/playgroup-narsingi/thematic-learning" className="nav-item">
            Learning at FKS
            {/* <div className="sub-nav" id="learning-nav" >
                            <a href="/board-and-curriculum" className="sub-nav-item">BOARD &amp; CURRICULUM</a>
                            <a href="/pedagogy" className="sub-nav-item">PEDAGOGY</a>
                            <a href="/academic-accolades" className="sub-nav-item">ACADEMIC ACCOLADES</a>
                            <a href="/special-educational-needs" className="sub-nav-item">SPECIAL EDUCATIONAL NEEDS</a>
                            <a href="/nutrition" className="sub-nav-item">NUTRITION</a>
                            <a href="/a-day-at-fks" className="sub-nav-item">A DAY AT FKS</a>
                        </div> */}
          </a>
          <a href="/playgroup-narsingi/well-being" className="nav-item">
            Beyond Learning
            {/* <div className="sub-nav" id="beyond-learning-nav" >
                            <a href="/sports-and-games" className="sub-nav-item">SPORTS &amp; GAMES</a>
                            <a href="/leadership" className="sub-nav-item">LEADERSHIP AT FKS</a>
                            <a href="/events-and-shows" className="sub-nav-item">EVENTS &amp; SHOWS</a>
                            <a href="/festivals" className="sub-nav-item">FESTIVALS &amp; CELEBRATIONS</a>
                            <a href="/theatre-and-drama" className="sub-nav-item">THEATRE &amp; DRAMA</a>
                            <a href="/field-trips" className="sub-nav-item">FIELD TRIPS &amp; PICNICS</a>
                        </div> */}
          </a>
          <a href="/playgroup-narsingi/campus" className="nav-item">
            Campus
          </a>
          {/* <a href="/" className="nav-item">Parents &amp; FKS</a>
                    <a href="/" className="nav-item">Admissions</a> */}
          <a href="/playgroup-narsingi/connect" className="nav-item">
            Get In Touch
          </a>
          {/* <a href="https://myskillpro.azurewebsites.net/" className="nav-item nav-item-myskillpro" target="_blank">Myskillpro Login</a>
                    <a href="https://fkshyderabad.codetantra.com/login.jsp" className="nav-item nav-item-codetantra" target="_blank">Code Tantra Login</a> */}
        </div>
      </div>

      <div className="nav-mob">
        <a href="/">
          <img className="nav-logo" src="/fks-logo.png"></img>
        </a>
        <div className="nav-item-container">
          <svg
            viewBox="0 0 100 80"
            width="40"
            height="55"
            onClick={(e) => {
              e.preventDefault();
              openSideNav(e);
            }}
          >
            <rect width="100" height="5"></rect>
            <rect y="20" width="100" height="5"></rect>
            <rect y="40" width="100" height="5"></rect>
          </svg>
        </div>
      </div>

      <div className="side-nav" id="side-nav">
        <div className="nav-items-container-mob">
          <a href="/playgroup-narsingi" className="nav-item-mob">
            HOME
          </a>
          <a href="/playgroup-narsingi/our-story" className="nav-item-mob">
            ABOUT US
          </a>
          <a
            href="/playgroup-narsingi/thematic-learning"
            className="nav-item-mob"
          >
            LEARNING AT FKS
          </a>
          <a href="/playgroup-narsingi/well-being" className="nav-item-mob">
            BEYOND LEARNING
          </a>
          <a href="/playgroup-narsingi/campus" className="nav-item-mob">
            CAMPUS
          </a>
          <a href="/playgroup-narsingi/connect" className="nav-item-mob">
            GET IN TOUCH
          </a>
          {/* <a href="https://myskillpro.azurewebsites.net/" className="nav-item-mob nav-item-mob-myskillpro" target="_blank">MYSKILLPRO LOGIN</a>
                    <a href="https://fkshyderabad.codetantra.com/login.jsp" className="nav-item-mob nav-item-mob-codetantra" target="_blank">CODE TANTRA LOGIN</a> */}
          <span
            className="nav-item-mob"
            onClick={(e) => {
              e.preventDefault();
              closeSideNav(e);
            }}
          >
            {/* <img src="/icons8-macos-close-96.png" className="nav-bar-close-icon" /> */}
            CLOSE
          </span>
        </div>
        {/* <div className="sub-nav-mob about-nav-mob" id="about-nav-mob">
                    <span className="nav-item-mob">
                        <a href="/our-story" className="sub-nav-item-mob">OUR STORY</a>
                        <a href="/vision-and-mission" className="sub-nav-item-mob">PHILOSOPHY</a>
                        <a href="/vision-and-mission" className="sub-nav-item-mob">VISION &amp; MISSION</a>
                        <a href="/fks-force" className="sub-nav-item-mob">FKS FORCE</a>
                        <a href="/news-and-highlights" className="sub-nav-item-mob">NEWS &amp; HIGHLIGHTS</a>
                    </span>
                </div>
                <div className="sub-nav-mob learning-nav-mob" id="learning-nav-mob">
                    <span className="nav-item-mob">
                        <a href="/board-and-curriculum" className="sub-nav-item-mob">BOARD &amp; CURRICULUM</a>
                        <a href="/pedagogy" className="sub-nav-item-mob">PEDAGOGY</a>
                        <a href="/academic-accolades" className="sub-nav-item-mob">ACADEMIC ACCOLADES</a>
                        <a href="/special-educational-needs" className="sub-nav-item-mob">SPECIAL EDU NEEDS</a>
                        <a href="/nutrition" className="sub-nav-item-mob">NUTRITION</a>
                        <a href="/a-day-at-fks" className="sub-nav-item-mob">A DAY AT FKS</a>
                    </span>
                </div>
                <div className="sub-nav-mob beyond-learning-nav-mob" id="beyond-learning-nav-mob">
                    <span className="nav-item-mob">
                        <a href="/sports-and-games" className="sub-nav-item-mob">SPORTS &amp; GAMES</a>
                        <a href="/leadership" className="sub-nav-item-mob">LEADERSHIP AT FKS</a>
                        <a href="/events-and-shows" className="sub-nav-item-mob">EVENTS &amp; SHOWS</a>
                        <a href="/festivals" className="sub-nav-item-mob">CELEBRATIONS</a>
                        <a href="/theatre-and-drama" className="sub-nav-item-mob">THEATRE &amp; DRAMA</a>
                        <a href="/field-trips" className="sub-nav-item-mob">FIELD TRIPS &amp; PICNICS</a>
                    </span>
                </div> */}
      </div>
      <div
        className="screen"
        onClick={(e) => {
          e.preventDefault();
          closeSideNav();
        }}
      ></div>
    </div>
  );
}

export default NavPlaygroupNarsingi;
