import React, { useState, useEffect } from "react";
import "./contact-us.css";
import AdmissionForm from "../admissions-form/admission-form";
import { Container, Row, Col, Table } from "react-bootstrap";

function ContactUsPlaygroupNarsingi() {
  /*useEffect(() => {
        const existingScript1 = document.getElementById("googleMaps");

        if (!existingScript1) {
            const script1 = document.createElement("script");
            script1.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCr7Xc7zyE1sOBe3GmLouPlp6P1_KrI9so&libraries=places";
            script1.id = "googleMaps";
            script1.async = true;

            document.body.appendChild(script1);

            script1.onload = () => {
                initMap1();
            };
        }

        if (existingScript1) initMap1();
    }, []);


    function initMap1() {
        var google1 = window.google;
        const myLatLng1 = { lat: 17.405980251143284, lng: 78.34963433422631 };
        const map1 = new google1.maps.Map(document.getElementById("map2"), {
            zoom: 16,
            center: myLatLng1
        });
        new google1.maps.Marker({
            position: myLatLng1,
            map1,
            title: "The Future Kids School"
        });
    }*/

  return (
    <div className="campus">
      <div className="cover">
        <img
          src="/playgroup-images/contact/Contact Us 2-min.jpg"
          className="cover-image campus-pg-cover-img"
        />
      </div>
      <div className="contact-us-dual-section">
        <div className="contact-us-dual-section-left">
          <div className="section">
            <div className="section-content contact-us-section-content">
              <span className="title">CONTACT US</span>
              <span className="content-text">
                <b>The Future Kid's Play Group</b>
                <br />
                H. No. 2-113/4/1 &amp; 2-113/4/2, Venkateswara kuteer,
                Manchirevula, Narsingi , Ranga Reddy District. Pin -500075
                <br />
                <br />
                <img
                  src="/icons8-phone-24.png"
                  className="pg-contact-us-icon"
                />
                <a href="tel:+91 7207990125" className="phone-text">
                  +91 72079 90125
                </a>
                <br />
                <img src="/icons8-mail-50.png" className="pg-contact-us-icon" />
                <a
                  href="mailto:connect@thefkspg.in"
                  style={{ textDecoration: "none", color: "#8A906B" }}
                >
                  connect@thefksnsg.in
                </a>
                <br />
              </span>
            </div>
          </div>

          <br />

          <div className="section">
            <div className="section-content contact-us-section-content">
              <span className="title">Admission Age Criteria (2024-25)</span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Year of Birth</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PRE - KG</td>
                    <td>2021</td>
                  </tr>
                  <tr>
                    <td>Lower - KG</td>
                    <td>2020</td>
                  </tr>
                  <tr>
                    <td>Upper - KG</td>
                    <td>2019</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="contact-us-dual-section-right admissions-form-section">
          <AdmissionForm />
        </div>
      </div>
      <a
        href="https://www.google.com/maps/place/Satva+shikshalay/@17.3779795,78.3667711,16.96z/data=!4m5!3m4!1s0x0:0x6e08aea25e020268!8m2!3d17.3782788!4d78.3661473"
        target="_blank"
      >
        <div className="contact-map-container">
          <img
            src="/fks-playgroup-narsingi-map.png"
            className="contact-page-map"
          />
        </div>
      </a>
      {/* <div className="contact-map" id="map2"></div> */}
    </div>
  );
}

export default ContactUsPlaygroupNarsingi;
