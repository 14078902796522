import React from "react";
import { useState, useEffect } from "react";
import "./contact-popup.css";

function ContactPopup() {

    // useEffect(() => {
    //     const existingScript = document.getElementById("googleMaps");

    //     if (!existingScript) {
    //         const script = document.createElement("script");
    //         script.src =
    //             "https://maps.googleapis.com/maps/api/js?key=AIzaSyCr7Xc7zyE1sOBe3GmLouPlp6P1_KrI9so&libraries=places";
    //         script.id = "googleMaps";
    //         script.async = true;

    //         document.body.appendChild(script);

    //         script.onload = () => {
    //             initMap();
    //         };
    //     }

    //     if (existingScript) initMap();
    // }, []);


    // function initMap() {
    //     var google = window.google;
    //     const myLatLng = { lat: 17.405980251143284, lng: 78.34963433422631 };
    //     const map = new google.maps.Map(document.getElementById("map"), {
    //         zoom: 14,
    //         center: myLatLng
    //     });
    //     new google.maps.Marker({
    //         position: myLatLng,
    //         map,
    //         title: "The Future Kids School"
    //     });
    // }

    function closeContactPopup() {
        document.getElementById("contact-popup").style.display = "none";
    }


    return (<div className="contact-popup" id="contact-popup" onClick={closeContactPopup}>
        <div className="contact-popup-box">
            <div className="content-popup-left" id="map">
            </div>
            <div className="content-popup-right">
                <label className="contact-popup-title">Address</label>
                <br />
                <label className="contact-popup-sub-title">The Future Kids School</label>
                <label className="contact-popup-content">Puppal Guda, Rajendranagar Mandal, RR District, Hyderabad Telangana</label>
                <br />
                <div className="contact-row"><label className="contact-popup-content">073066 19742</label></div>
                <div className="contact-row"><label className="contact-popup-content">contact@thefks.in</label></div>
            </div>
            <div className="close-popup-container">
                <img src="/cancel.svg" className="contact-popup-close-icon" onClick={closeContactPopup} />
            </div>
        </div>
    </div>)
}

export default ContactPopup;